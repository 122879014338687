import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Importando useNavigate para redirecionamento
import { collection, addDoc, Timestamp, query, where, getDocs } from "firebase/firestore";
import { db } from '../firebaseConfig';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'; // Importando React Toastify
import 'react-toastify/dist/ReactToastify.css'; // Estilos do Toastify
import './uayy.css';
import { Analytics } from "@vercel/analytics/react"; // Import Analytics component from Vercel
import i18n from 'i18next'; // Import i18next diretamente
import { useTranslation, initReactI18next } from 'react-i18next'; // React hook para traduções

// Definindo traduções diretamente no código
const resources = {
  en: {
    translation: {
      "Like X, but Y": "Like X, but Y",
      "Fast and lightweight": "Fast and lightweight",
      "Built for modern users": "Built for modern users",
      "Decentralized by design": "Decentralized by design",
      "Your data, your control": "Your data, your control",
      "Made to be Your space": "Made to be Your space",
      "Select for what you want in Y:": "Select for what you want in Y:",
      "How about the design:": "How about the design:",
      "How about the technology:": "How about the technology:",
      "A Simpler Twitter": "A Simpler Twitter",
      "Just like X": "Just like X",
      "Enhanced Twitter": "Enhanced Twitter",
      "Simple design": "Simple design",
      "Detailed design": "Detailed design",
      "Simpler, but fast": "Simpler, but fast",
      "Make it quantum proof": "Make it quantum proof",
      "Enter your email": "Enter your email",
      "Become an Early-Seed": "Become an Early-Seed"
    }
  },
  pt: {
    translation: {
      "Like X, but Y": "Como X, mas Y",
      "Fast and lightweight": "Rápido e leve",
      "Built for modern users": "Feito para usuários modernos",
      "Decentralized by design": "Descentralizado por design",
      "Your data, your control": "Seus dados, seu controle",
      "Made to be Your space": "Feito para ser seu espaço",
      "Select for what you want in Y:": "Selecione o que você quer no Y:",
      "How about the design:": "Que tal o design:",
      "How about the technology:": "Que tal a tecnologia:",
      "A Simpler Twitter": "Um Twitter mais simples",
      "Just like X": "Igual ao X",
      "Enhanced Twitter": "Twitter aprimorado",
      "Simple design": "Design simples",
      "Detailed design": "Design detalhado",
      "Simpler, but fast": "Mais simples, mas rápido",
      "Make it quantum proof": "Segurança anti-computador quantico",
      "Enter your email": "Digite seu e-mail",
      "Become an Early-Seed": "Torne-se um Early-Seed"
    }
  }
};

// Inicializa o i18next com as traduções
i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Idioma padrão é o inglês
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // React já faz escape por padrão
  },
});

function Uayy() {
  const { t } = useTranslation(); // Hook para traduzir conteúdo
  const [firstSelection, setFirstSelection] = useState(null);
  const [secondSelection, setSecondSelection] = useState(null);
  const [thirdSelection, setThirdSelection] = useState(null);
  const [email, setEmail] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const navigate = useNavigate(); // Hook para redirecionamento

  // Obter o endereço IP e definir a língua ao carregar o componente
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const countryCode = response.data.country_code;
        setIpAddress(response.data.ip);

        // Mudar o idioma com base no país
        if (countryCode === 'BR') {
          i18n.changeLanguage('pt');
        } else {
          i18n.changeLanguage('en');
        }
      } catch (error) {
        console.error("Erro ao obter IP: ", error);
      }
    };
    fetchIpAddress();
  }, []);

  const selectFirstOption = (option) => setFirstSelection(option);
  const selectSecondOption = (option) => setSecondSelection(option);
  const selectThirdOption = (option) => setThirdSelection(option);

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email && firstSelection && secondSelection && thirdSelection) {
      try {
        // Verificar se o IP já enviou dados nas últimas 1 minuto
        const oneMinuteAgo = Timestamp.now().toMillis() - 60 * 1000; // 1 minuto em milissegundos
        const q = query(
          collection(db, "submissions"),
          where("ipAddress", "==", ipAddress),
          where("timestamp", ">", new Date(oneMinuteAgo))
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          toast.info('You have submitted recently. Please wait for 1 minute before submitting again.', {
            position: "top-right", // Alterar para canto superior direito
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: '#333333',
              color: '#FFA800'
            },
          });
          return;
        }

        // Adicionar dados ao Firestore
        await addDoc(collection(db, "submissions"), {
          email: email,
          selection1: firstSelection,
          selection2: secondSelection,
          selection3: thirdSelection,
          ipAddress: ipAddress,
          timestamp: Timestamp.now() // Adiciona timestamp para registro
        });

        toast.success(`Email submitted: ${email}\nYou are now part of the Early-Seed program!`, {
          position: "top-right", // Alerta no canto superior direito
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#505050',
            color: '#FFA800'
          },
        });

        // Redirecionar para a página de Early-Seed após 3 segundos
        setTimeout(() => {
          navigate('/early-seed');
        }, 3000);

      } catch (error) {
        toast.error('There was an error submitting your response. Please try again.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#333333',
            color: '#FFA800'
          },
        });
        console.error("Error submitting the form", error);
      }
    } else {
      toast.warn('Please select all options and provide a valid email.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#333333',
          color: '#FFA800'
        },
      });
    }
  };

  return (
    <div className="container">
      {/* Seção da Logo */}
      <div className="logo">
        <img src="whay.wtf.png" alt="Whay Logo" />
      </div>

      {/* Texto vertical com os conceitos principais */}
      <div className="key-concepts">
        <p>{t("Like X, but Y")}</p>
        <p>{t("Fast and lightweight")}</p>
        <p>{t("Built for modern users")}</p>
        <p>{t("Decentralized by design")}</p>
        <p>{t("Your data, your control")}</p>
        <p>{t("Made to be Your space")}</p>
      </div>

      {/* Texto de ajuda para seleção */}
      <p className="help-text">{t("Select for what you want in Y:")}</p>

      {/* Primeira Linha de Seleções (123) */}
      <div className="selection">
        <div className={`selectable ${firstSelection === 1 ? 'selected' : ''}`} onClick={() => selectFirstOption(1)}>
          <p>{t("A Simpler Twitter")}</p>
        </div>
        <div className={`selectable ${firstSelection === 2 ? 'selected' : ''}`} onClick={() => selectFirstOption(2)}>
          <p>{t("Just like X")}</p>
        </div>
        <div className={`selectable ${firstSelection === 3 ? 'selected' : ''}`} onClick={() => selectFirstOption(3)}>
          <p>{t("Enhanced Twitter")}</p>
        </div>
      </div>
      
      {/* Texto de ajuda para design */}
      <p className="help-text">{t("How about the design:")}</p>

      {/* Segunda Linha de Seleções (456) */}
      <div className="selection">
        <div className={`selectable ${secondSelection === 4 ? 'selected' : ''}`} onClick={() => selectSecondOption(4)}>
          <p>{t("Simple design")}</p>
        </div>
        <div className={`selectable ${secondSelection === 5 ? 'selected' : ''}`} onClick={() => selectSecondOption(5)}>
          <p>{t("Just like X")}</p>
        </div>
        <div className={`selectable ${secondSelection === 6 ? 'selected' : ''}`} onClick={() => selectSecondOption(6)}>
          <p>{t("Detailed design")}</p>
        </div>
      </div>

      {/* Texto de ajuda para tecnologia */}
      <p className="help-text">{t("How about the technology:")}</p>

      {/* Terceira Linha de Seleções (789) */}
      <div className="selection">
        <div className={`selectable ${thirdSelection === 7 ? 'selected' : ''}`} onClick={() => selectThirdOption(7)}>
          <p>{t("Simpler, but fast")}</p>
        </div>
        <div className={`selectable ${thirdSelection === 8 ? 'selected' : ''}`} onClick={() => selectThirdOption(8)}>
          <p>{t("Just like X")}</p>
        </div>
        <div className={`selectable ${thirdSelection === 9 ? 'selected' : ''}`} onClick={() => selectThirdOption(9)}>
          <p>{t("Make it quantum proof")}</p>
        </div>
      </div>

      {/* Seção de Envio de Email */}
      <div className="bottom-section">
        <form onSubmit={handleSubmit}>
          <input 
            type="email" 
            value={email} 
            onChange={handleEmailChange} 
            placeholder={t("Enter your email")} 
            required
            className="email-input"
          />
          <button type="submit" className="submit-button">{t("I want Uayy!")}</button>
        </form>
      </div>

      {/* Toastify Container para exibir notificações */}
      <ToastContainer />

      {/* Adicionando o componente Analytics no final */}
      <Analytics />
    </div>
  );
}

export default Uayy;
