import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBMNDLIexpZPz9nNxt7PdO6QEbvfx7yOmU",
  authDomain: "whay-8e585.firebaseapp.com",
  projectId: "whay-8e585",
  storageBucket: "whay-8e585.appspot.com",
  messagingSenderId: "968640223242",
  appId: "1:968640223242:web:3681612a964621ad7ca7f3",
  measurementId: "G-394SY30MWZ"
};

// Initialize Firebase
// Inicializando o Firebase
const app = initializeApp(firebaseConfig);

// Inicializando o Firestore
const db = getFirestore(app);

export { db };