import React from 'react';
import './earlySeed.css'; // Arquivo CSS para estilos personalizados

function EarlySeed() {
  return (
    <div className="early-seed-container">
      <div className="early-seed-message">
        <h1>Thank You!</h1>
        <p>A new <span className="highlight">Uayy</span> of socializing is being built just for you.</p>
        <p>Stay tuned for exciting updates as we create something revolutionary!</p>
      </div>
    </div>
  );
}

export default EarlySeed;
